<template>
  <Dialog
    v-model:visible="showModal"
    :modal="true"
    header="Edit Mailing"
    :dismissable-mask="true"
    :close-on-escape="true"
  >
    <div :style="{ width: '50vw' }">
      <div class="card card-w-title">
        <div class="p-grid form-group">
          <div>
            <div class="p-field mb-4">
              <label for="emailInput" class="block text-900 font-medium mb-2"
                >Name</label
              >
              <InputText
                type="text"
                id="emailInput"
                class="p-inputtext p-component w-full p-3"
                :class="
                  validationErrors && validationErrors.name ? 'p-invalid' : ''
                "
                v-model="form.name"
              />
              <small
                class="p-error"
                v-if="validationErrors && validationErrors.name"
              >
                {{ validationErrors.name[0] }}
              </small>
            </div>

            <div class="p-field mb-3">
              <label
                for="descriptionInput"
                class="block text-900 font-medium mb-2"
                >Description</label
              >
              <InputText
                type="text"
                id="descriptionInput"
                class="p-inputtext p-component w-full p-3"
                :class="
                  validationErrors && validationErrors.description
                    ? 'p-invalid'
                    : ''
                "
                v-model="form.description"
                v-on:keyup.enter="console.log()"
              />
              <small
                class="p-error"
                v-if="validationErrors && validationErrors.description"
              >
                {{ validationErrors.description[0] }}
              </small>
            </div>

            <div class="p-field mb-3">
              <label
                for="descriptionInput"
                class="block text-900 font-medium mb-2"
                >Mailing Date</label
              >
              <Calendar class="py-3" v-model="form.mailing_date" />
              <small
                class="p-error"
                v-if="validationErrors && validationErrors.mailing_date"
              >
                {{ validationErrors.mailing - date[0] }}
              </small>
            </div>

            <Button
              label="UPDATE"
              icon="pi pi-user"
              class="p-button p-component w-full p-3"
              :loading="loading"
              @click="saveChanges"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import EventBus from "@/EventBus";
import Mailing from "@/models/Mailing";

export default {
  name: "EditMailing",
  props: {
    mailing: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["updated"],
  data() {
    return {
      form: {
        name: "",
        description: "",
        mailing_date: "",
      },
      validationErrors: [],
      loading: false,

      showModal: false,
    };
  },
  watch: {
    mailing() {
      this.form.name = this.mailing.name;
      this.form.description = this.mailing.description;
      this.form.mailing_date = this.mailing.mailing_date;
    },
  },
  mounted() {
    EventBus.on("toggle-client-modal", () => {
      this.showModal = !this.showModal;
    });
  },
  methods: {
    saveChanges() {
      this.loading = true;

      let mailing = new Mailing({});

      mailing.id = this.mailing.id;
      mailing.name = this.form.name;
      mailing.description = this.form.description;
      mailing.mailing_date = this.form.mailing_date;

      mailing
        .save()
        .then(() => {
          this.$emit("updated", { data: this.form, mailing: this.mailing });
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Mailing Updated",
            life: 3000,
          });
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
          this.showModal = false;
        });
    },
  },
};
</script>
